import Bugsnag from '@bugsnag/js';

const ALLOWED_ENVIRONMENTS = ['production', 'staging', 'sandbox'];

const getMetaData = (name) => {
  const $el = document.querySelector(`meta[name="${name}"]`);

  try {
    return JSON.parse($el.getAttribute('content'));
  } catch (e) {}
};

// https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/
const isInlineScript = (error) => {
  const jsFrame = error.stacktrace[0];
  return jsFrame && !/\.js$/.test(jsFrame.file);
};

const isErrorClass = (error, errorClass) => {
  return (error.errorClass || '') === errorClass || (error.errorMessage || '').indexOf(errorClass) !== -1;
};

// Via browserlist, we have opted not to support older browsers.
// As a result, we don't need to report these.
const isOldUnsupportedMethod = (error, errorClass) => {
  return [error.errorClass, error.errorMessage].join(' ').indexOf('Array.from') !== -1;
};

const handleBugsnagEvent = (event) => {
  const err = event.errors[0];

  if (
    isErrorClass(err, 'InvalidError') ||
    isErrorClass(err, 'SyntaxError') ||
    err.errorMessage === "Object doesn't support property or method 'warn'" ||
    err.errorMessage === "'console' is undefined" ||
    isInlineScript(err) ||
    isOldUnsupportedMethod(err)
  )
    // Don't send these to Bugsnag
    return false;
};

export default () => {
  const data = getMetaData('bugsnag');

  if (
    !data ||
    !data.api_key ||
    !data.env ||
    ALLOWED_ENVIRONMENTS.indexOf(data.env) === -1
  )
    return;

  Bugsnag.start({
    apiKey: data.api_key,
    releaseStage: data.env,
    enabledReleaseStages: ALLOWED_ENVIRONMENTS,
    maxBreadcrumbs: 40,
    onError: handleBugsnagEvent
  });
};

export { handleBugsnagEvent };
